import React, { useEffect, useState } from "react";

import { fetchCmcReadings } from "./fetchHandlers";
import { ClassicTable } from "../../../utils/components/Styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function CMCTable(props) {
  const { instrumentId } = props;

  const [cmcReadings, setCmcReadings] = useState([]);

  useEffect(() => {
    if (instrumentId > 0) {
      fetchCmcReadings(instrumentId, setCmcReadings);
    }
  }, [instrumentId]);

  if (cmcReadings.length < 1) return <></>;

  return (
    <div id="datasheet_editdatasheet_cms">
      <h4 style={{ marginBottom: "0px" }}>CMC</h4>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography noWrap>Sr. No.</Typography>
                </TableCell>
                {cmcReadings && cmcReadings.some((row) => row.mode) && (
                  <TableCell>
                    <Typography noWrap>Mode</Typography>
                  </TableCell>
                )}
                {cmcReadings && cmcReadings.some((row) => row.etParameter) && (
                  <TableCell>
                    <Typography noWrap>ETParameter</Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography noWrap>Parameter Type</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>From range</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>To range</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Lower CMC</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Higher CMC</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Least Count</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cmcReadings.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {cmcReadings && cmcReadings.some((row) => row.mode) && (
                    <TableCell>{row.mode}</TableCell>
                  )}
                  {cmcReadings && cmcReadings.some((row) => row.etParameter) && (
                    <TableCell>{row.etParameter}</TableCell>
                  )}
                  <TableCell>{row.paratype}</TableCell>
                  <TableCell>{row.fromRange?.replaceAll("#", " ")}</TableCell>
                  <TableCell>{row.toRange?.replaceAll("#", " ")}</TableCell>
                  <TableCell>{row.lowerCmc?.replaceAll("#", " ")}</TableCell>
                  <TableCell>{row.higherCmc?.replaceAll("#", " ")}</TableCell>
                  <TableCell>{row.lc?.replaceAll("#", " ")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>

        <br />
      </div>
    </div>
  );
}
