import React, { useContext } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DatasheetContext } from "./datasheet.context";
import { convertUnit } from "../../uncertainties/utils";

export default function StandardMasterSelection(props) {
  const {
    index,
    label,
    standardRanges,
    updateStandardRangesOfRow,
    isSupportive,
    valId,
    rowIndex,
    table,
  } = props;
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: { staticReadingRows },
  } = datasheetContextValue;

// Helper function to get the latest unit row index
const getLatestUnitRowIndex = (rowIndex, staticReadingRows) => {
  for (let i = rowIndex; i >= 0; i--) {
    if (staticReadingRows[i]["c1"]?.includes("_unit_")) {
      return i;
    }
  }
  return -1;
};

// Helper function to handle unit conversion
const convertToReadingUnit = (range, readingUnit) => {
  if (range.unit !== readingUnit) {
    range.value = convertUnit(range.value, range.unit, readingUnit);
    range.unit = readingUnit;
  }
  return range;
};

// Helper function to calculate the tolerance-adjusted range
const applyTolerance = (minRangeValue, maxRangeValue, tolerance = 0.02) => {
  const rangeTolerance = (maxRangeValue - minRangeValue) * tolerance;
  return {
    min: minRangeValue - rangeTolerance,
    max: maxRangeValue + rangeTolerance
  };
};

// Optimized standard range filtration
const newRanges = standardRanges.filter((rangeObj) => {
  // Validate prerequisites
  if (!staticReadingRows || !table?.defaultConfiguration?.rangeCol) {
    return false;
  }

  // Separate minimum range and maximum range
  let [minRange, maxRange] = rangeObj.range.split("|").map((part) => {
    const [value, unit] = part.split("#");
    return { value: parseFloat(value), unit };
  });

  // Set maximum range value if not available
  let maxRangeValue = (!maxRange?.value || isNaN(maxRange.value)) ? minRange.value : maxRange.value;

  // Find range column index
  const rangeCol = table?.defaultConfiguration?.rangeCol;

  // Find the latest unit row index
  const immediateParentUnitRowIndex = getLatestUnitRowIndex(rowIndex, staticReadingRows);

  // Get the latest reading value and unit
  const readingValue = parseFloat(staticReadingRows?.[rowIndex]?.[rangeCol]);
  const readingUnit = staticReadingRows?.[immediateParentUnitRowIndex]?.[rangeCol]?.split("_unit_")?.[1];

  // Convert units if necessary
  minRange = convertToReadingUnit(minRange, readingUnit);
  maxRangeValue = convertUnit(maxRangeValue, minRange.unit, readingUnit);

  // Apply a 2% tolerance to the range
  const { min: minTol, max: maxTol } = applyTolerance(minRange.value, maxRangeValue);

  // Check if the reading value falls within the tolerance-adjusted range
  return (
    readingValue >= minTol &&
    readingValue <= maxTol &&
    readingUnit === minRange.unit
  );
});

// Apply standard filtration logic
const stdFilteration = table?.defaultConfiguration?.stdFilteration;

// If filteredRanges is empty, return the entire standardRanges array
const filteredRanges = stdFilteration && newRanges.length > 0 ? newRanges : standardRanges;


  return (
    <div>
      <Autocomplete
        fullWidth
        id="checkboxes-tags-demo"
        size="small"
        options={
          filteredRanges != null
            ? filteredRanges?.map((item) => {
                return {
                  id: item.id,
                  stId: item.stId,
                  title: item.title,
                  rangeId: item.rangeId,
                  range: item.range?.replaceAll("#", ""),
                  accuracy: item.accuracy?.replaceAll("#", ""),
                  lc: item.lc?.replaceAll("#", ""),
                  uniformity: item.uniformity?.replaceAll("#", ""),
                  stability: item.stability?.replaceAll("#", ""),
                  mode: item.mode,
                  parameter: item.parameter,
                  etParameter: item.etParameter,
                  paratype: item.paratype,
                  dsKey: item.dsKey,
                };
              })
            : []
        }
        value={
          valId
            ? filteredRanges
                ?.filter((stdr) => valId === "" || valId == stdr.rangeId)
                .map((stdr) => {
                  if (stdr === "") return undefined;
                  if (isSupportive) {
                    return {
                      id: stdr.id,
                      stId: stdr.stId,
                      title: stdr.title,
                      rangeId: stdr.rangeId,
                      range: stdr.range?.replaceAll("#", ""),
                      uniformity: stdr.uniformity?.replaceAll("#", ""),
                      stability: stdr.stability?.replaceAll("#", ""),
                    };
                  }
                  return {
                    id: stdr.id,
                    stId: stdr.stId,
                    title: stdr.title,
                    rangeId: stdr.rangeId,
                    range: stdr.range?.replaceAll("#", ""),
                    accuracy: stdr.accuracy?.replaceAll("#", ""),
                    lc: stdr.lc?.replaceAll("#", ""),
                    mode: stdr.mode,
                    parameter: stdr.parameter,
                    etParameter: stdr.etParameter,
                    paratype: stdr.paratype,
                    dsKey: stdr.dsKey,
                  };
                })[0]
            : undefined
        }
        getOptionLabel={(option) => {
          return `${option.stId} : ${option.title}, ${`Range : ${
            option.range || ""
          }`}, ${
            !isSupportive
              ? `Accuracy: ${option.accuracy || ""}`
              : `Uniformity: ${option.uniformity || ""}`
          }, ${
            !isSupportive
              ? `LC : ${option.lc || ""}`
              : `Stability : ${option.stability || ""}`
          }, ${!isSupportive ? `Parameter: ${option.parameter || ""}` : ""}, ${
            !isSupportive && option.dsKey == "0"
              ? `Mode : ${option.mode || ""}`
              : ""
          }, ${
            !isSupportive && option.dsKey == "0"
              ? `ET Parameter: ${option.etParameter || ""}`
              : ""
          }, ${
            !isSupportive && option.dsKey == "0"
              ? `Parameter Type : ${option.paratype || ""}`
              : ""
          }`;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ fontSize: "11px" }} >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.stId} <b>:</b> {option.title}, &nbsp;&nbsp;
            <span style={{ color: "	#335FFF" }}>
              Range: {option.range},&nbsp;&nbsp;
            </span>
            {isSupportive && (
              <span style={{ color: "#335FFF" }}>
                Uniformity: {option.uniformity},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && (
              <span style={{ color: "#148F77" }}>
                Accuracy: {option.accuracy},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && (
              <span style={{ color: "	#FF5533" }}>
                LC: {option.lc},&nbsp;&nbsp;
              </span>
            )}
            {isSupportive && (
              <span style={{ color: "	#FF5533" }}>
                Stability: {option.stability},&nbsp;&nbsp;
              </span>
            )}
            {!isSupportive && option.dsKey == "0" ? (
              <>
                <span style={{ color: "	#335FFF" }}>
                  Mode: {option.mode},&nbsp;&nbsp;
                </span>
                <span style={{ color: "	#148F77" }}>
                  ET Parameter: {option.etParameter},&nbsp;&nbsp;
                </span>
                <span style={{ color: "	#FF5533" }}>
                  Parameter Type: {option.paratype},&nbsp;&nbsp;
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "	#148F77" }}>
                  Parameter: {option.parameter},&nbsp;&nbsp;
                </span>
              </>
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="select multiple standards"
          />
        )}
        onChange={(event, value) => {
          updateStandardRangesOfRow(
            value ? `${value?.id}:${value?.rangeId}` : "",
            isSupportive ? -1 : index + 1
          );
        }}
        // disableCloseOnSelect
      />
    </div>
  );
}
