import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { FormControl } from "@mui/material";
import { ClassicTable } from "../../utils/components/Styles";
import { showComponent } from "../helper/helpers";
import ExcelUpload from "../../utils/components/excelUpload";
import SplitButton from "./splitButton";

import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

const readingColumns = [
  [
    {
      field: "id",
      headerName: "Sr. No.",
      editable: false,
      width: 10,
    },
    {
      field: "srfInstrumentId",
      headerName: "Equipment Name",
      editable: true,
    },
    {
      field: "rangeValue",
      headerName: "Range",
      editable: true,
    },
    {
      field: "discription",
      headerName: "Description",
      editable: true,
    },
    {
      field: "HSNCode",
      headerName: "HSN/SAC Code",
      editable: true,
    },
    {
      field: "service",
      headerName: "Service",
      editable: true,
    },
    {
      field: "rate",
      headerName: "Rate",
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      editable: true,
    },
    {
      field: "discount",
      headerName: "Discount (%)",
      editable: true,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      editable: true,
    },
  ],
];

const remarkOptions = [
  "NABL",
  "Non_NABL",
  "Not_In_Our_Scope",
  "Repairing",
  "New_Supply",
  "Calibration",
];

export const fetchAddresses = async (addressIds, shippingAddressIds) => {
  // Check for invalid inputs
  let allIDS = `${addressIds},${shippingAddressIds}`;
  const containsNonText = /[a-zA-Z]/.test(allIDS);

  if (containsNonText) {
    // If invalid IDs are found, return an empty array or handle the case accordingly
    return [];
  }

  // Convert input strings to arrays of numbers
  const parseIds = (ids) => ids.split(",").map(Number);

  const sqlQuery = {
    query: `SELECT * FROM addresses WHERE id IN (${
      parseIds(addressIds).join(",") || 0
    }, ${parseIds(shippingAddressIds).join(",") || 0})`,
  };

  try {
    const addressesResponse = await axiosWithToken.post(
      BASE_URL + `dynamic`,
      sqlQuery
    );
    const addresses = addressesResponse.data; // Assuming response data contains addresses

    const billingAddressArray = parseIds(addressIds);
    const shippingAddressArray = parseIds(shippingAddressIds);

    const result = billingAddressArray.map((id, index) => {
      const address = addresses.find((a) => a.id === id);
      const shippingId = shippingAddressArray[index];
      const shippingInfo = addresses.find((a) => a.id === shippingId);

      const addressId = address ? address.id : null;
      const addressAddress = address ? address.address : "";

      return {
        id: addressId,
        address: addressAddress,
        shipping: shippingInfo
          ? { id: shippingInfo.id, address: shippingInfo.address }
          : { id: null, address: "" },
      };
    });

    return result;
  } catch (error) {
    // Handle any errors that occur during the process
    console.error("Error fetching addresses:", error);
    return []; // Return an empty array or handle the error accordingly
  }
};

const ProductDetails = ({
  productArray,
  uniqueProductsByRange,
  setproductReadingRows,
  productReadingRows,
  state,
  setState,
  updateQuoObject,
  grandTotal,
  setGrandTotal,
  invoiceObject,
  getAllProducts,
  getAllSrfsProducts,
  isMergedProduct,
  isInvoice,
}) => {
  const [excelArray, setExcelArray] = React.useState([]);
  const [totalAmount, setTotal] = React.useState("");

  const addNewProductRow = () => {
    const newProduct = {
      equipmentId: null,
      rangeValue:null,
      description: null,
      hsnCode: null,
      service: null,
      rate: null,
      quantity: null,
      discount: null,
      amount: null,
      type: null,
      referenceId: null,
      breakupId: null,
    };

    setproductReadingRows((prevRows) => [...prevRows, newProduct]);
  };

  const updateProductCellValue = (index, field, value) => {
    setproductReadingRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
      return updatedRows;
    });
  };

  const deleteProductRow = (indexToDelete) => {
    setproductReadingRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(indexToDelete, 1);
      return updatedRows;
    });
  };

  const handleAddNumber = () => {
    const lastNumber =
      state.partiallyNumbers[state.partiallyNumbers.length - 1];
    setState((prevState) => ({
      ...prevState,
      partiallyNumbers: [...prevState.partiallyNumbers, lastNumber + 1],
    }));
  };

  const updateExcelReading = () => {
    if (excelArray) {
      let allProducts = [...productReadingRows, ...excelArray];
      const mergedProducts = Object.values(
        allProducts.reduce((acc, obj) => {
          const key = obj.rate + "_" + obj.equipmentId;
          if (acc[key]) {
            acc[key].quantity += obj.quantity;
          } else {
            acc[key] = { ...obj };
          }
          return acc;
        }, {})
      ).map((product) => ({
        ...product,
        amount: product.rate * product.quantity,
      }));

setproductReadingRows(isMergedProduct == 1?mergedProducts:allProducts);
    }
  };

  const Calculate = () => {
    const discount = invoiceObject.discount || 0;
    let grandTotal = productReadingRows.reduce(
      (sum, val) =>
        Number(sum) +
        (Number(val.rate) *
          Number(val.quantity) *
          (100 - Math.min(Number(val?.discount || 0), 100))) /
          100,
      0
    );
    let newGrandTotal = grandTotal + 
      Number(invoiceObject.conveyanceCharges || 0) +
      Number(invoiceObject.courierCharges || 0);

    const discountAmount = (grandTotal * discount) / 100;
    updateQuoObject("discountAmount", -discountAmount);
    const totalAmountWithDiscount = newGrandTotal - discountAmount;

    setTotal(totalAmountWithDiscount.toFixed(2));

    let GstAmount =
      (totalAmountWithDiscount *
        (invoiceObject?.GSTPercentage ? invoiceObject?.GSTPercentage : 18)) /
      100;

    setGrandTotal((totalAmountWithDiscount + GstAmount).toFixed(2));

    // setGrandTotal(
    //   (
    //     totalAmountWithDiscount *
    //     (invoiceObject.gstType?.value !== 3 &&
    //     invoiceObject.gstType?.value !== null
    //       ? 1.18
    //       : 1)
    //   ).toFixed(2)
    // );
  };

  // Function to find product by equipmentId in productArray
  const findProductByEquipmentId = (equipmentId, productArray) => {
    return productArray.find((e) => e.id === equipmentId);
  };

  useEffect(() => {
    Calculate();
  }, [
    invoiceObject.discount,
    productReadingRows,
    invoiceObject.conveyanceCharges,
    invoiceObject.courierCharges,
    invoiceObject.gstType,
    invoiceObject?.GSTPercentage,
  ]);

  useEffect(() => {
    if (excelArray) updateExcelReading();
  }, [excelArray,isMergedProduct]);

  const invoicesData = () => {
    if (invoiceObject.gstType?.value === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="quotation_create_ProductDetails_SGST"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(
                (totalAmount *
                  (invoiceObject?.GSTPercentage
                    ? invoiceObject?.GSTPercentage / 2
                    : 9)) /
                100
              ).toFixed(2)}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="quotation_create_ProductDetails_CGST"
              label="CGST"
              disabled
              value={(
                (totalAmount *
                  (invoiceObject?.GSTPercentage
                    ? invoiceObject?.GSTPercentage / 2
                    : 9)) /
                100
              ).toFixed(2)}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else if (invoiceObject.gstType?.value === 2) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="quotation_create_ProductDetails_GST"
              label="IGST"
              value={(
                (totalAmount *
                  (invoiceObject?.GSTPercentage
                    ? invoiceObject?.GSTPercentage
                    : 18)) /
                100
              ).toFixed(2)}
              InputLabelProps={{ shrink: true }}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else {
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginTop: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="SGST"
            InputLabelProps={{ shrink: true }}
            value={0}
            disabled
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="CGST"
            disabled
            value={0}
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>;
    }
  };

  return (
    <div>
      <hr />
      <h4 style={{ "marginBottom": "0px" }}>PRODUCT DETAILS</h4>
      {isInvoice && (
        <div style={{ display: "flex", gap: "15px", margin: "20px 2px" }}>
          <Button variant="contained" color="primary" onClick={handleAddNumber}>
            Add Partially Invoice
          </Button>
          <FormControl sx={{ width: "250px" }} size="small">
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={state.partiallyNumbers}
              value={state?.breakupId}
              getOptionLabel={(option) => {
                if (option === "Final") {
                  return "Final Invoice";
                } else {
                  return `${option} Partially Invoice`;
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Partially Invoice" />
              )}
              onInputChange={(event, newInputValue) => {
                setState({
                  ...state,
                  breakupId: newInputValue.trim().split(" ")[0],
                });
              }}
            />
          </FormControl>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px",
        }}
      >
        <div>
          <ExcelUpload
            setExcelArray={setExcelArray}
            buttonName={"Upload Excel File"}
          />
        </div>

        {isInvoice ? (
          <SplitButton
            addDescriptionRow={addNewProductRow}
            productReadingRows={productReadingRows}
            getAllProducts={getAllProducts}
            getAllSrfsProducts={getAllSrfsProducts}
            setproductReadingRows={setproductReadingRows}
          />
        ) : (
          <Button
            id="quotation_create_ProductDetails_addnewproduct"
            variant="contained"
            onClick={(e) => {
              addNewProductRow(0);
            }}
          >
            <AddIcon />
            Add New Product
          </Button>
        )}
      </div>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productReadingRows.map((row, index) => {
                return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_equipmentId"
                    >
                    <Autocomplete
                    style={{ width: "200px" }}
                      disabled={row.id}
                      size="small"
                      id={`combo-box-demo-${row.id}`}
                      key={findProductByEquipmentId(
                        row.equipmentId,
                        productArray
                      )}
                      options={productArray}
                      // value={productArray.find((e) => e.id === row.equipmentId)}
                      value={findProductByEquipmentId(
                        row.equipmentId,
                        productArray
                      )}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                      onChange={(event, value) => {
                        if (value != null) {
                          const newRate = value.rate;
                          updateProductCellValue(
                            index,
                            "equipmentId",
                            value.id
                          );
                          updateProductCellValue(
                            index,
                            "name",
                            value.name
                          );
                          updateProductCellValue(
                            index,
                            "rate",
                            parseFloat(newRate)
                          );
                          updateProductCellValue(
                            index,
                            "quantity",
                            parseFloat(1)
                          );
                          updateProductCellValue(
                            index,
                            "amount",
                            parseFloat(newRate * 1)
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_rangeValue"
                    >
                    <Autocomplete
                    style={{ width: "200px" }}
                      disabled={row.id}
                      size="small"
                      id={`combo-box-demo-${row.id}`}
                      key={uniqueProductsByRange?.find((e) => e.rangeValue == row.rangeValue)}
                      options={row?.name ? uniqueProductsByRange?.filter((ele)=>ele.name == row?.name) : []}
                      getOptionLabel={(option) => option?.rangeValue?.replace(/#/g, ' ')?.replace(/\|/g, ' to ')?.replace(/\s+/g, ' ')?.trim()}
                      value={uniqueProductsByRange?.find((e) => e.rangeValue == row.rangeValue)}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                      onChange={(event, value) => {
                        if (value != null) {
                          const newRate = value.rate;
                          updateProductCellValue(
                            index,
                            "rangeValue",
                            value.rangeValue
                          );
                          updateProductCellValue(
                            index,
                            "rate",
                            parseFloat(newRate)
                          );
                          updateProductCellValue(
                            index,
                            "quantity",
                            parseFloat(1)
                          );
                          updateProductCellValue(
                            index,
                            "amount",
                            parseFloat(newRate * 1)
                          );
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_description"
                    >
                    <TextField
                    style={{ width: "200px" }}
                      id={`outlined-basic-description-${row.id}`}
                      size="small"
                      fullWidth
                      value={row.description}
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      onChange={(e) => {
                        updateProductCellValue(
                          index,
                          "description",
                          e.target.value
                        );
                      }}
                      disabled={row.equipmentId === ""}
                    />
                  </TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_hsnCode"
                    >
                    <TextField
                    style={{ width: "100%" }}
                      id={`outlined-basic-hsnCode-${row.id}`}
                      size="small"
                      fullWidth
                      value={row.hsnCode}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      type="text"
                      onChange={(e) => {
                        updateProductCellValue(
                          index,
                          "hsnCode",
                          e.target.value
                        );
                      }}
                      disabled={row.equipmentId === ""}
                    />
                  </TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_service"
                    >
                    <Autocomplete
                    style={{ width: "200px" }}
                      id={`combo-box-service-${row.id}`}
                      options={remarkOptions}
                      fullWidth
                      size="small"
                      value={row.service}
                      onChange={(event, value) => {
                        updateProductCellValue(index, "service", value || "");
                      }}
                      inputValue={row.service}
                      onInputChange={(event, newInputValue) => {
                        updateProductCellValue(index, "service", newInputValue);
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          InputLabelProps={{ shrink: true }}
                          disabled={row.equipmentId === ""}
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell
                    id="quotation_create_ProductDetails_rate"
                    >
                    <TextField
                    style={{ width: "60px" }}
                      id={`outlined-basic-rate-${row.id}`}
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={row.rate}
                      variant="standard"
                      type="number"
                      inputProps={{ min: "0" }} 
                      // onBlur={(e) => {
                      //   const inputValue = parseFloat(e.target.value);
                      //   if (isNaN(inputValue)) {
                      //     e.target.style.borderColor = 'red';
                      //   } else if (inputValue < 0) {
                      //     e.target.style.borderColor = 'red';
                      //   } else {
                      //     e.target.style.borderColor = ''; // Reset border color if condition is not met
                      //   }
                      // }}
                      onChange={(e) => {
                        // if (e.target.value) {
                          updateProductCellValue(
                            index,
                            "rate",
                            parseFloat(e.target.value)
                          );
                          updateProductCellValue(
                            index,
                            "amount",
                            parseFloat(row.quantity) *
                              parseFloat(e.target.value)
                          );
                        // }
                      }}
                      disabled={row.equipmentId === ""}
                    />
                  </TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_quantity"
                    >
                    <TextField
                    style={{ width: "100%" }}
                      id={`outlined-basic-quantity-${row.id}`}
                      size="small"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={row.quantity}
                      variant="standard"
                      type="number"
                        inputProps={{ min: "0" ,autoComplete: 'off',}} 
                      onChange={(e) => {
                        // if (e.target.value) {
                          updateProductCellValue(
                            index,
                            "quantity",
                            parseFloat(e.target.value)
                          );
                          let totalAmount =
                            parseFloat(row.rate) * parseFloat(e.target.value);
                          let discountAmount =
                            (totalAmount * parseFloat(row.discount)) / 100;
                          updateProductCellValue(
                            index,
                            "amount",
                            totalAmount - discountAmount
                          );
                        // }
                      }}
                      disabled={row.equipmentId === ""}
                    />
                  </TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_discount"
                    >
                    <TextField
                    style={{ width: "100%" }}
                      id={`outlined-basic-description-${row.id}`}
                      size="small"
                      fullWidth
                      type="number"
                      value={row.discount}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                        inputProps={{ min: "0",autoComplete: 'off', }} 
                      onChange={(e) => {
                        updateProductCellValue(
                          index,
                          "discount",
                          e.target.value
                        );
                        let totalAmount =
                          parseFloat(row.rate) * parseFloat(row.quantity);
                        let discountAmount =
                          (totalAmount * parseFloat(e.target.value)) / 100;
                        updateProductCellValue(
                          index,
                          "amount",
                          totalAmount - discountAmount
                        );
                      }}

                      // disabled={row.equipmentId === ""}
                    />
                  </TableCell>
                  <TableCell
                    id="quotation_create_ProductDetails_amount"
                    >
                    <TextField
                    style={{ width: "90px" }}
                      id={`outlined-basic-amount-${row.id}`}
                      size="small"
                      fullWidth
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={
                        (parseFloat(row.rate) *
                          parseFloat(row.quantity) *
                          (100 -
                            Math.min(parseFloat(row?.discount || 0), 100))) /
                          100 || 0
                      }
                      variant="standard"
                      disabled
                    />
                  </TableCell>

                  <TableCell>
                    {showComponent("delete") && (
                      <DeleteIcon
                        id="quotation_create_ProductDetails_proddelete"
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          const confirmation = window.confirm(
                            "Are you sure you want to delete this Product?"
                          );
                          if (confirmation) {
                            deleteProductRow(index, row.id);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              
              )}
              )}
              <br />
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br/>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="quotation_create_ProductDetails_conveyanceCharges"
            label="Onsite Charges"
            size="small"
            inputProps={{
              autoComplete: 'off',
            }}
            value={invoiceObject.conveyanceCharges}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("conveyanceCharges", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            id="quotation_create_ProductDetails_courierCharges"
            label="Courier Charges"
            size="small"
            inputProps={{
              autoComplete: 'off',
            }}
            value={invoiceObject.courierCharges}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateQuoObject("courierCharges", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      {invoiceObject.gstType?.value !== 3 &&
      invoiceObject.gstType?.value !== null ? (
        <>
          <br />
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "10px" }}
            justifyContent="flex-end"
          >
            <Grid item xs={3}>
              <TextField
                id="quotation_create_ProductDetails_bottomdiscount"
                label="Discount (%) "
                size="small"
                inputProps={{
                  autoComplete: 'off',
                }}
                value={invoiceObject.discount || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateQuoObject("discount", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "10px" }}
            justifyContent="flex-end"
          >
            <Grid item xs={3}>
              <TextField
                disabled
                id="quotation_create_ProductDetails_totalAmount"
                label="Total Amount"
                size="small"
                inputProps={{
                  autoComplete: 'off',
                }}
                value={totalAmount}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
      {invoiceObject.gstType?.value !== 3 &&
      invoiceObject.gstType?.value !== null
        ? invoicesData()
        : ""}

      <br />
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px" }}
        justifyContent="flex-end"
      >
        <Grid item xs={3}>
          <TextField
            disabled
            id="quotation_create_ProductDetails_grandTotal"
            label="Grand Total Amount"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              autoComplete: 'off',
            }}
            size="small"
            value={grandTotal ? grandTotal : grandTotal}
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
      <br />
    </div>
  );
};

export default ProductDetails;
