import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../psuedoApi";

export const [
  getClientsThunk
] = [
  ["clients", "getClients"]
].map((paths) =>
  createAsyncThunk(`data/${paths[0]}`, async (params, thunkApi) => {
    console.log("here 0!");
    let data = thunkApi.getState().globalData[paths[0]];
    if (!data.length || (params && params.refresh))
      data = await request({
        url: paths[1],
        params,
        type: "get",
      });
    switch (paths[0]) {
      case "clients":
        data = data.map((c) => {
          try {
            c.contact = JSON.parse(c.contact);
          } catch (e) {}
          return c;
        });
        thunkApi.dispatch(setClients({ clients: data }));
        break;
      default:
        break;
    }
  })
);

const initialState = {
  clients: [],
  datasheetReadingDetails:{
  groupedDsReadingRanges: {} // datasheet readings range value grouped by unit
  
  }
};

export const globalDataSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload.clients;
    },
    setGroupedDsReadingRanges: (state, action) => {
      state.datasheetReadingDetails.groupedDsReadingRanges = action.payload.groupedDsReadingRanges
    }
  },
});

export const {
  setClients,
  setGroupedDsReadingRanges
} = globalDataSlice.actions;

export const getClients = (state) => state.globalData.clients;


export default globalDataSlice.reducer;