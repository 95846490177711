import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { BASE_URL } from "../../global";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { ClassicTable } from "./../../utils/components/Styles";

import { typeA_columns, typeB_columns, budget_columns } from "../../constants/uncertaintiesConstants";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import DownloadPdfLoaderModal from "../../utils/components/downloadPdfLoader";
import { processReports } from "./process";

const ShowTable = ({ headers, readings }) => {
  return (
    <ClassicTable>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((e, idx) => (
              <TableCell key={`${idx}`}>
                {typeof e.label === "function" ? e.label() : e.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {readings.map((reading, idx) => (
            <TableRow key={`${idx}`}>
              {reading.map((col, id) => {
                let value =
                  typeof col === "string"
                    ? col?.replaceAll('#', " ")
                    : typeof col === "function"
                      ? col()
                      : typeof col?.[0] === "function"
                        ? col?.[0]()
                        : col?.[0]?.replaceAll('#', " ");

                let tooltip = String(value).split("||")[1];
                value = typeof value === "string" ? String(value).split("||")[0] : value;

                if (tooltip) {
                  tooltip = tooltip?.split("//");
                  tooltip = (
                    <div>
                      Formula: {tooltip?.[0]?.replaceAll("$", "")}<br />
                      <hr />
                      Resolved Formula: {tooltip?.[1]}
                    </div>
                  );
                  return (
                    <TableCell
                      colSpan={typeof col === "string" ? 1 : col?.[1] || 1}
                      key={`${idx}-${id}`}
                      style={{
                        color:
                          typeof col === "string"
                            ? col.toString().includes("[wrong formula]")
                              ? "red"
                              : "inherit"
                            : null,
                      }}
                    >
                      <Tooltip title={tooltip}>
                        {value}
                      </Tooltip>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      colSpan={typeof col === "string" ? 1 : col?.[1] || 1}
                      key={`${idx}-${id}`}
                      style={{
                        color:
                          typeof col === "string"
                            ? col.toString().includes("[wrong formula]")
                              ? "red"
                              : "inherit"
                            : null,
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ClassicTable>
  );
};

export default function ViewAll() {
  const {
    datasheetId,
    instrumentId,
    tableId,
  } = useParams();

  const groupedDsReadingRanges = JSON.parse(sessionStorage.getItem('groupedDsReadingRanges'))

  const [reportDetails, setReportDetails] = useState(null);
  const [allKFactorReferenceData, setAllKFactorReferenceData] = useState([]);
  const [isKvalue, setIsKvalue] = useState(true);
  const [downloadPdfloading, setDownlaodPdfLoading] = useState(false);

  const navigate = useNavigate();

  const printComponentRef = useRef();

  const generateDynamicFooter = async (pageNo, totalPages) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `
    <div style="padding:30px;">
    <table style="width:100%;">
        <tr>
        <td colspan="2" style="border:none;padding:5px;font-family: Calibri, sans-serif;text-align:right">
            <b style="font-size:20px;">Page Number : </b>
            <span style="font-size:19px;">${pageRange}</span>
          </td>
        </tr>
    </table>
</div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 1, scale: 1 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generatePDF = async () => {
    setDownlaodPdfLoading(true);
    try {
      const element = printComponentRef.current;
      let pdf = await html2pdf()
        .from(element)
        .set({
          margin: [30, 1, 20, 1],
          pagebreak: { after: "section", mode: ["css", "legacy"] },
          html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true,
          },
          jsPDF: {
            orientation: "portrait",
            unit: "pt",
            format: "a4",
            compress: true,
          },
        })
        .toPdf()
        .get("pdf");

      const totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(9);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("Courier");

        // border add for contnet
        const borderWidth = 0.6;
        const leftMargin = 15;
        const borderX = leftMargin;
        const borderY = 30;
        const rightMargin = 595 - leftMargin * 0.95;
        pdf.setLineWidth(borderWidth);
        pdf.rect(borderX, borderY, rightMargin - leftMargin, 800);

        let image = await generateDynamicFooter(i, totalPages);
        pdf.addImage(image, 0, 0, 595, 40);

        // if (letterHead) {
        //   image = await generateLetterHeadFooter();
        //   pdf.addImage(image, 0, 772, 595, 70);
        // }
      }

      const blob = pdf.output("blob");
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `budget_${datasheetId}.pdf`;
      a.click();

      setDownlaodPdfLoading(false);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setDownlaodPdfLoading(false);
    }
  };

  const errorFn = (errTxt, err) => {
    toast.error(errTxt + err);
    console.error(errTxt, err);
    return ["err", err];
  };

  const trimPrecision = (value) => {
    // Convert value to string if it's not already a strin
    value = String(value);
    let unit = value.split("#")[1];
    value = value.split("#")[0];

    // Check if the value is in exponential format
    if (value.includes("e") || value.includes("E")) {
      // Parse the number to preserve exponent notation
      let parsedValue = parseFloat(value);
      // Convert back to exponential format with 7 precision
      value = parsedValue.toExponential(7);
    } else {
      // Convert the value to number
      let numValue = Number(value);
      // Check if the number has more than 7 significant digits
      if (numValue.toString().length > 7) {
        // Convert number to string and trim precision
        let trimmedValue = numValue.toFixed(7);
        // Convert back to number and return
        value = Number(trimmedValue);
      }
    }

    return String(value) + (unit ? "#" + unit : "");
  };

  const buildTypeA = (rows, second) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip
            label={`TYPE-A ${second ? 2 : 1} EVALUATION`}
            style={{ fontWeight: "bold" }}
          />
        </Divider>
        <ShowTable headers={typeA_columns} readings={rows} />
        <Grid
          container
          spacing={2}
          sx={{ mt: 2 }}
          style={{ marginTop: "80px" }}
        >
          <Grid item xs={4}>
            <Typography align="center">Calibrated By</Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography align="center">Approved By</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const buildTypeB = (rows) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip
            label="Type-B Contribution
          "
            style={{ fontWeight: "bold" }}
          />
        </Divider>
        <ShowTable headers={typeB_columns} readings={rows} />
      </Paper>
    );
  };

  const buildBudget = (rows, params) => {
    return (
      <Paper sx={{ mx: 5, mt: 2, p: 2 }}>
        <Divider sx={{ mb: 1, mt: 2 }}>
          <Chip label="Uncertainty Budget" style={{ fontWeight: "bold" }} />
        </Divider>

        <ShowTable headers={budget_columns} readings={rows} />

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="COMBINED UNCERTAINTY (Uc)"
              value={params?.uc?.replaceAll("#", " ")}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="EFFECTIVE DEGREES OF FREEDOM"
              value={params?.freedom}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Expanded Uncertainty
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={"text"}
                value={params?.finalExpandedUncertainty?.replaceAll("#", " ")}
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        <div style={{ fontSize: "12px" }}>
                          calculated uncertainty:{" "}
                          {params?.uncertainty?.replaceAll("#", " ")}
                          <br />
                          {params?.additionalCmcData}
                          <br />
                          CMC Range:{" "}
                          {params?.cmcFromRange?.replaceAll("#", " ")} to{" "}
                          {params?.cmcToRange?.replaceAll("#", " ")}
                          <br />
                          lower CMC:{" "}
                          {params?.lowerCMC?.replaceAll("#", " ") || "NA"}
                          <br />
                          higher CMC:{" "}
                          {params?.higherCMC?.replaceAll("#", " ") || "NA"}
                        </div>
                      }
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
                label="Expanded Uncertainty "
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const fetchKFactorReferenceData = () => {
    try {
      const sqlQuery = {
        query: `SELECT kr.* FROM KFactorReference kr JOIN settings s ON kr.fractionPercent = s.value WHERE s.keyName = 'KFactorFactionPercent' AND s.status = 1`,
      };

      axiosWithToken
        .post(BASE_URL + `dynamic`, sqlQuery)
        .then((res) => {
          setAllKFactorReferenceData(res.data);
          // Check if data is empty and set isKvalue accordingly
          setIsKvalue(res.data.length > 0);
        })
        .catch((error) => {
          console.log(error);
          // Set isKvalue to false on error
          setIsKvalue(false);
        });
    } catch (error) {
      console.log("KFactorReference data fetching error: ", error);
      // Set isKvalue to false on error
      setIsKvalue(false);
    }
  };

  useEffect(() => {
    fetchKFactorReferenceData();
  }, []);

  useEffect(() => {
    if (allKFactorReferenceData?.length > 0) {
      processReports(
        setReportDetails,
        tableId,
        datasheetId,
        instrumentId,
        groupedDsReadingRanges,
        navigate
      );
    }
  }, [allKFactorReferenceData, tableId, datasheetId, instrumentId, navigate]);

  if (!reportDetails || allKFactorReferenceData?.length == 0) {
    return (
      <>
        {!isKvalue ? (
          <Paper
            sx={{
              textTransform: "capitalize",
              height: "40%",
              width: "70%",
              margin: "30px auto",
              p: 2,
              display: "grid",
              placeItems: "center",
            }}
          >
            <h2 style={{ color: "red" }}>
              K value is not available!
              <h4 style={{ textTransform: "capitalize" }}>
                please contact the support team. <br /> Tel: +91 9769847865
              </h4>
            </h2>
          </Paper>
        ) : null}
        {isKvalue && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={350} thickness={2}></CircularProgress>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {!isKvalue ? (
          <Paper
            sx={{
              textTransform: "capitalize",
              height: "40%",
              width: "70%",
              margin: "30px auto",
              p: 2,
              display: "grid",
              placeItems: "center",
            }}
          >
            <h2 style={{ color: "red" }}>
              K value is not available!
              <h4 style={{ textTransform: "capitalize" }}>
                please contact the support team. <br /> Tel: +91 9769847865
              </h4>
            </h2>
          </Paper>
        ) : null}
        {isKvalue && (
          <>
            {reportDetails.length > 0 && (
              <Toolbar
                sx={{ displayPrint: "none" }}
                style={{ padding: "0px", width: "100%" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{ ml: 3, displayPrint: "none" }}
                  onClick={() => generatePDF()}
                >
                  Budget Pdf
                </Button>
              </Toolbar>
            )}
            <div ref={printComponentRef}>
              {reportDetails &&
                reportDetails.map((u, idx) => (
                  <div key={`uncertainty-${idx}`}>
                    {u[0]?.[0]?.length > 0 ? (
                      <>
                        <div>{buildTypeA(u[0][0])}</div>
                        <Divider
                          sx={{ mb: 1, mt: 2, displayPrint: "none" }}
                        ></Divider>
                      </>
                    ) : (
                      ""
                    )}
                    {u[0]?.[1]?.length > 0 ? (
                      <>
                        <div>{buildTypeA(u[0][1], true)}</div>
                        <Divider
                          sx={{ mb: 1, mt: 2, displayPrint: "none" }}
                        ></Divider>
                      </>
                    ) : (
                      ""
                    )}
                    <div>{u[1] && buildTypeB(u[1])}</div>
                    <div>{u[2][0] && buildBudget(u[2][0], u[2][1])}</div>
                  </div>
                ))}
              <div style={{ width: "100%" }} id="header-section"></div>
            </div>
          </>
        )}

        {downloadPdfloading && (
          <DownloadPdfLoaderModal
            loading={downloadPdfloading}
            name={"Budget"}
          />
        )}
      </>
    );
  }
}
