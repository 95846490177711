import React, { useEffect, useState } from "react";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import { Button, Tooltip } from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import ResourceModel from "../trainingResources/resourceModel";

export const SopViewFile = ({ instrumentId }) => {
  const [loading, setLoading] = useState(false);
  // Resource Modal state
  const [openModalResource, setOpenModalResource] = React.useState(false);
  const [modalResourceUrl, setModalResourceUrl] = React.useState(""); 
  const handleOpenModalResource = (url) => {
    setModalResourceUrl(url);
    setOpenModalResource(true);
  }; 
  const handleCloseModalResource = () => {
    setOpenModalResource(false);
    setModalResourceUrl("");
  };

  function getInstrumentSopFile() {
    setLoading(true);
    axiosWithToken
      .get(`${BASE_URL}instruments/${instrumentId}`)
      .then((response) => {
        if (response.data[0]?.sopUpload) {
          handleOpenModalResource(response.data[0]?.sopUpload);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Tooltip title="View SOP" placement="top-start">
      <Button onClick={getInstrumentSopFile} disabled={loading}>
        <MemoryIcon />
      </Button>
      <ResourceModel
        open={openModalResource}
        onClose={handleCloseModalResource}
        resourceUrl={modalResourceUrl}
      />
    </Tooltip>
  );
};

export const TrainingAndMasterViewFile = ({ calibratedbyID }) => {
  const [loading, setLoading] = useState(false);
   // Resource Modal state
   const [openModalResource, setOpenModalResource] = React.useState(false);
   const [modalResourceUrl, setModalResourceUrl] = React.useState(""); 
   const handleOpenModalResource = (url) => {
     setModalResourceUrl(url);
     setOpenModalResource(true);
   }; 
   const handleCloseModalResource = () => {
     setOpenModalResource(false);
     setModalResourceUrl("");
   };

  function getCalibratedUserTraiingCertiifcate() {
    setLoading(true);
    axiosWithToken
      .get(`${BASE_URL}users/${calibratedbyID}`)
      .then((response) => {
        if (response.data[0]?.trainingCertificate) {
          handleOpenModalResource(response.data[0]?.trainingCertificate);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Tooltip title="View Training Certificate" placement="top-start">
      <Button onClick={getCalibratedUserTraiingCertiifcate} disabled={loading}>
        <ModelTrainingIcon />
      </Button>
      <ResourceModel
        open={openModalResource}
        onClose={handleCloseModalResource}
        resourceUrl={modalResourceUrl}
      />
    </Tooltip>
  );
};
